<template>
    <nav>
        <v-app-bar fixed fade-on-scroll elevation="1" height="48" color="blue darken-2" app>
            <v-toolbar-title class="" style="font-size: 16px!important;">
                <router-link to="/" style="text-decoration: none" v-if="!isAuthenticated">
                    <span class="white--text overline">LoginShield Administration</span>
                </router-link>
                <router-link to="/dashboard" style="text-decoration: none" v-if="isAuthenticated">
                    <span class="white--text overline">LoginShield Administration</span>
                </router-link>
            </v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                color="blue"
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <template v-if="isAuthenticated">
                <!-- Show toolbar buttons on large screens
                <div v-show="$vuetify.breakpoint.smAndUp">
                    <v-tabs slider-color="white" background-color="blue darken-2" right>
                        <v-tab to="/" v-show="false"></v-tab>
                        <v-tab to="/dashboard/user"  class="white--text">Users</v-tab>
                        <v-tab to="/dashboard/account"  class="white--text">Accounts</v-tab>
                        <v-tab to="/dashboard/realm"  class="white--text">Realms</v-tab>
                    </v-tabs>
                </div> -->
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn text v-on="on" color="white">
                            <Avatar :attr="mainProfile" :size="36"/>
                            <font-awesome-icon icon="caret-down" class="pl-2 white--text" fixed-width size="1x"/>
                        </v-btn>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item-group>
                        <v-list-item :to="{ name: 'profile' }">
                            <v-list-item-title>My Profile</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click="onLogout">
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                        </v-list-item-group>
                        <!-- <div>
                            <v-tabs vertical slider-color="#307FE2" class="menutabs">
                                <v-tab to="/dashboard" v-show="false"></v-tab> -->
                                <!-- Show these in menu if screen is too small to fit buttons -->
                                <!-- <div v-show="$vuetify.breakpoint.smAndDown">
                                    <v-tab to="/dashboard/user"  class="black--text">Users</v-tab>
                                    <v-tab to="/dashboard/account"  class="black--text">Accounts</v-tab>
                                    <v-tab to="/dashboard/realm"  class="black--text">Realms</v-tab>
                                </div> -->
                                <!-- Show these in menu always -->
                                <!-- <v-tab :to="{ name: 'profile' }">My Profile</v-tab>
                                <v-divider></v-divider>
                                <v-tab @click="onLogout">Logout</v-tab> -->
                            <!-- </v-tabs>
                        </div> -->
                    </v-list>
                </v-menu>
            </template>
        </v-app-bar>
    </nav>
</template>
<style>
.v-tab--active.black--text {
    opacity: 0.95 !important;
}
.black--text {
    opacity: 0.8 !important;
}
</style>
<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';

// this.$store.commit('isLoading', true);
// this.$route.name   ->   so we can change menu items based on current route (current page)
// current route names:  front, about, privacy, software, terms, login, locked, me, device, select-profile, profile, test

export default {
    components: {
        Avatar,
    },
    methods: {
        async onLogout() {
            console.log('onLogout: redirecting to front page');
            await this.$store.dispatch('logout');
            if (this.$route.name !== 'front') {
                this.$router.push('/');
            }
        },
    },

    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
        }),
        ...mapGetters({
            mainProfile: 'mainProfile', // currently selected account object
            isLoading: 'isLoading',
        }),
    },
};
</script>

<style>
    div.menutabs div[role="tablist"] { width: 100%; }
    div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
</style>
