import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/Interaction.vue'),
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Index.vue'),
        },
        {
            path: '/profile/authn',
            name: 'profile-settings-authn',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn" */ '../views/profile/Authn.vue'),
        },
        {
            path: '/profile/authz',
            name: 'profile-settings-authz',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authz" */ '../views/profile/Authz.vue'),
        },
        {
            path: '/profile/session',
            name: 'profile-settings-session',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-session" */ '../views/profile/Session.vue'),
        },
        {
            path: '/service',
            name: 'service-admin',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin" */ '../views/service/Index.vue'),
        },
        {
            path: '/service/setup',
            name: 'service-admin-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin-setup" */ '../views/service/Setup.vue'),
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        },
        {
            path: '/dashboard/user',
            name: 'user-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-dashboard" */ '../views/UserDashboard.vue'),
        },
        {
            path: '/dashboard/account',
            name: 'account-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-dashboard" */ '../views/AccountDashboard.vue'),
        },
        {
            path: '/dashboard/realm',
            name: 'realm-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-dashboard" */ '../views/RealmDashboard.vue'),
        },
        {
            path: '/account/create',
            name: 'account-create',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create" */ '../views/AccountCreate.vue'),
        },
        {
            path: '/account/link-user',
            name: 'account-link-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-link-user" */ '../views/AccountLinkUser.vue'),
        },
        {
            path: '/search/user',
            name: 'search-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "search-user" */ '../views/SearchUser.vue'),
        },
        {
            path: '/view/user',
            name: 'view-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "view-user" */ '../views/ViewUser.vue'),
        },
        {
            path: '/report/list',
            name: 'report-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "report-list" */ '../views/ReportList.vue'),
        },
        {
            path: '/report/view',
            name: 'report-view',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "report-view" */ '../views/ReportView.vue'),
        },
        // {
        //     path: '/webauthz/grant',
        //     name: 'webauthz-grant',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "webauthz-grant" */ '../views/webauthz/Grant.vue'),
        // },
        // {
        //     path: '/webauthz/prompt',
        //     name: 'webauthz-prompt',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "webauthz-prompt" */ '../views/webauthz/Prompt.vue'),
        // },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
