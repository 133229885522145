<template>
    <component :is="layout">
        <Snackbar :attr="snackbarAttr"/>
        <template v-if="isPublic || (isAuthenticatedReady && isAuthenticated)">
            <router-view></router-view>
        </template>
        <template v-if="!isPublic && !isAuthenticatedReady">
            <v-row style="height: 100%" align="center" justify="center">
                <div class="app-splash-loader"></div>
            </v-row>
        </template>
        <template v-if="!isPublic && isAuthenticatedReady && !isAuthenticated">
            <v-row style="height: 100%" align="center" justify="center">
            <v-card elevation="4">
                <v-toolbar dense flat color="blue darken-2 white--text">
                    <v-toolbar-title>Login to continue</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    The requested resource is only available to authenticated users.
                </v-card-text>
                <v-card-actions class="my-2">
                    <v-spacer/>
                    <v-btn @click="login" elevation="4" color="blue darken-2 white--text">Login</v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
            </v-row>
        </template>
    </component>
</template>

<style lang="css">
html {
  overflow-y: auto; /* reference: https://vuetifyjs.com/en/getting-started/frequently-asked-questions/ "The scrollbar is showing even though my content is not overflowing vertically" */
}

.app-splash-loader {
    border: 4px solid #307FE2;
    border-top: 4px solid #003DA5;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin: auto;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>

<script>
import { mapState } from 'vuex';
// import '@/assets/font/NunitoSans/NunitoSans.css'
import Snackbar from '@/components/Snackbar.vue';

export default {
    name: 'App',

    components: {
        Snackbar,
    },

    data: () => ({
        snackbarAttr: {},
    }),

    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isPublic() {
            return this.$route.meta.isPublic ?? false;
        },
        layout() {
            return this.$route.meta.layout ?? 'main-layout';
        },
    },

    methods: {
        async login() {
            const { fullPath } = this.$router.currentRoute;

            // // create a new interaction so after login the user will be
            // // redirected back to the current page
            // const createInteractionResponse = await this.$client.interaction.create({
            //     type: 'require_login',
            //     state: {
            //         redirect: fullPath,
            //     },
            // });
            // if (typeof createInteractionResponse !== 'object' || createInteractionResponse.error || !createInteractionResponse.isCreated || !createInteractionResponse.id) {
            //     this.loginError = true;
            //     return;
            // }

            // this.$router.push({ path: '/login', query: { i: createInteractionResponse.id } });
            this.$router.push({ path: '/login', query: { next: fullPath } });
        },
        // async createAccount() {
        //     const { fullPath } = this.$router.currentRoute;

        //     // create a new interaction so after login the user will be
        //     // redirected back to the current page
        //     const createInteractionResponse = await this.$client.interaction.create({
        //         type: 'require_login',
        //         state: {
        //             redirect: fullPath,
        //         },
        //     });
        //     if (typeof createInteractionResponse !== 'object' || createInteractionResponse.error || !createInteractionResponse.isCreated || !createInteractionResponse.id) {
        //         this.createAccountError = true;
        //         return;
        //     }

        //     this.$router.push({ path: '/create-account', query: { i: createInteractionResponse.id } });
        // },
    },

    created() {
        console.log('app.vue: created, initializing');
        this.$store.dispatch('init');

        // call when tab is selected
        window.onfocus = () => {
            this.$store.dispatch('refresh');
        };

        // listen for snackbar events coming from any component
        this.$bus.$on('snackbar', (attr) => { this.snackbarAttr = attr; });
    },
};
</script>
