import Vue from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser, faEnvelope, faPhone, faCheck, faFile,
    faCheckSquare, faPlus, faTrash, faSave, faCamera,
    faTimes, faSort, faSortDown, faCaretDown,
    faBriefcase, faCompress, faDesktop, faShieldAlt, faHeart, faUserSecret,
    faBuilding, faFingerprint, faCode, faBars, faUserCircle,
    faPencilAlt, faSearch, faEdit, faInfoCircle,
    faCloudDownloadAlt, faUnlock,
    faEye as faEyeSolid,
    faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
    faMinusSquare, faPaperclip, faArrowLeft,
    faEllipsisV as faEllipsisVSolid,
    faCloudUploadAlt as faCloudUploadAltSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faIdBadge as faIdBadgeSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare,
} from '@fortawesome/free-regular-svg-icons';
// import {
//     faWordpress as faWordpressBrand,
// } from '@fortawesome/free-brands-svg-icons';
import {
    faEllipsisV as faEllipsisVRegular,
} from '@fortawesome/pro-regular-svg-icons';

function setup() {
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.component('font-awesome-layers', FontAwesomeLayers);
    Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
    library.add(faUser, faEnvelope, faPhone, faCheck, faFile, faCheckSquare, faSquare,
        faPlus, faTrash, faSave, faCamera, faBriefcase, faCompress, faDesktop, faShieldAlt,
        faTimes, faSort, faSortDown, faCaretDown,
        faHeart, faBuilding, faUserSecret, faFingerprint, faCode, faBars, faUserCircle,
        faPencilAlt, faSearch, faEdit, faInfoCircle,
        faCloudDownloadAlt, faUnlock,
        faEyeSolid,
        faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
        faMinusSquare, faPaperclip, faArrowLeft,
        faEllipsisVSolid, faCloudUploadAltSolid, faExclamationTriangleSolid,
        faQuestionCircleSolid,
        faIdBadgeSolid,
        faEllipsisVRegular);
}

function mapFontAwesomeIcons(iconMap) {
    const output = {};
    Object.entries(iconMap).forEach(([key, value]) => {
        output[key] = {
            component: FontAwesomeIcon,
            props: {
                icon: value,
            },
        };
    });
    return output;
}

export default { mapFontAwesomeIcons, setup };
