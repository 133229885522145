/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : null, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJsonWithQuery(path, query, requestBody, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, requestBody ? JSON.stringify(requestBody) : null, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class Service {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async getInfo() {
        return getJson(`${this.url}`);
    }

    async getVersion() {
        return getJson(`${this.url}/service/version`, null, { requestHeaders: this.requestHeaders });
    }

    async getSettingsList() {
        return getJson(`${this.url}/service/config`, null, { requestHeaders: this.requestHeaders });
    }

    async editSetting(request) {
        return postJsonAcceptJson(`${this.url}/service/config`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class Authn {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}/authn/session`, request, { requestHeaders: this.requestHeaders });
    }

    async startLogin(request) {
        return postJsonAcceptJson(`${this.url}/authn/login/start`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkLogin(request) {
        return postJsonAcceptJson(`${this.url}/authn/login/check`, request, null, { requestHeaders: this.requestHeaders });
    }

    async prefsRedirect(request) {
        return postJsonAcceptJson(`${this.url}/authn/prefs-redirect`, request, null, { requestHeaders: this.requestHeaders });
    }

    async logout(request = {}) {
        return postJsonAcceptJson(`${this.url}/authn/logout`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class CurrentUser {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get() {
        return getJson(`${this.url}/current-user`, null, { requestHeaders: this.requestHeaders });
    }

    async edit(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/current-user/edit`, request, null, { requestHeaders: this.requestHeaders });
    }

    async refresh(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/current-user/refresh`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/current-user/delete`, null, null, { requestHeaders: this.requestHeaders });
    }
}

class User {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/org/${context.organizationId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/user/create`, request, null, { requestHeaders: this.requestHeaders });
    }

    // TODO: move to unicorn springs
    async invite(request) {
        return postJsonAcceptJson(`${this.url}/user/invite`, request, null, { requestHeaders: this.requestHeaders });
    }

    // request like { email (string), agreeToTerms (boolean) }
    // TODO: move to unicorn springs
    async activate(request) {
        return postJsonAcceptJson(`${this.url}/user/activate`, request, null, { requestHeaders: this.requestHeaders });
    }

    // TODO: move to unicorn springs
    async list(request) {
        return getJson(`${this.url}/search/user`, request, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/user`, { id }, { requestHeaders: this.requestHeaders });
    }

    // TODO: move to unicorn springs
    async edit(id, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/user/edit`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    // TODO: move to unicorn springs
    async delete({ id }) {
        return postJsonAcceptJson(`${this.url}/user/delete`, null, { id }, { requestHeaders: this.requestHeaders });
    }
}

class Interaction {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/interaction/create`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/interaction`, { id }, { requestHeaders: this.requestHeaders });
    }

    async resume(token) {
        return postJsonAcceptJson(`${this.url}/interaction/resume`, { token }, null, { requestHeaders: this.requestHeaders });
    }

    async edit(id, message) {
        return postJsonAcceptJson(`${this.url}/interaction/edit`, message, { id }, { requestHeaders: this.requestHeaders });
    }

    async getTokenStatus(tokenId) {
        console.log('getTokenStatus');
        return getJson(`${this.url}/interaction/token/status`, { tokenId }, { requestHeaders: this.requestHeaders });
    }
}

class Webauthz {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    // for authorization server
    async getAccessPrompt(id) {
        return getJson(`${this.url}/webauthz/prompt`, { id });
    }

    // for authorization server
    async grantAccess(id, permit = {}) {
        return postJsonAcceptJson(`${this.url}/webauthz/prompt`, { id, submit: 'grant', permit });
    }

    // for authorization server
    async denyAccess(id) {
        return postJsonAcceptJson(`${this.url}/webauthz/prompt`, { id, submit: 'deny' });
    }
}

class Account {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/account/create`, request);
    }

    // TODO: move to LinkAccountUser.create
    async linkUser({ accountId, userId }) {
        return postJsonAcceptJson(`${this.url}/account/link-user`, { accountId, userId });
    }

    async edit(accountId, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJsonWithQuery(`${this.url}/account/edit`, { id: accountId }, request);
    }

    /*
    async search() {
        return getJson('/data/search', { reportId: 1 });
    }

    async report() {
        return getJson('/data/report', { reportId: 1 });
    }

    */
}

class LinkAccountUser {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    // NOTE: currently link account user records are created with Account.linkUser ; TODO: move to LinkAccountUser.create
    /*
    async create(request) {
        return postJsonAcceptJson('/link-account-user/create', request);
    }
    */

    /*
    async search() {
        return getJson('/data/search', { reportId: 1 });
    }

    async report() {
        return getJson('/data/report', { reportId: 1 });
    }
    */
    async edit(id, request) {
        // NOTE: you only need to specify the attributes that should be changed
        // return postJsonAcceptJsonWithQuery('/link-account-user/edit', { id }, request);
        return postJsonAcceptJsonWithQuery(`${this.url}/data/edit`, { type: 'link-account-user', id }, request);
    }
}

class Report {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }
    // async create(request) {
    // return postJsonAcceptJson('/report/create', request);
    // }

    async get() {
        return getJson(`${this.url}/report`);
    }

    // async edit(request) {
    // NOTE: you only need to specify the attributes that should be changed
    // return postJsonAcceptJson('/user/edit', request);
    // }
}

class Data {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    // async create(request) {
    // return postJsonAcceptJson('/report/create', request);
    // }

    /*
    async search({ reportId }) {
        return getJson('/data/search', { reportId });
    }
    */

    async report(params) {
        return getJson(`${this.url}/data/report`, params);
    }

    // NOTE: item 'type' and 'id' are required, besides these you only need to specify the attributes that should be changed
    async editItemById(query, request) {
        return postJsonAcceptJsonWithQuery(`${this.url}/data/edit`, query, request);
    }

    // TODO: move 'type' and 'id' to query parameters, the post body should be empty
    async deleteItemById(params) {
        return postJsonAcceptJson(`${this.url}/data/delete`, params);
    }
}

class Client {
    constructor(context = {}) {
        this.account = new Account(context);
        this.authn = new Authn(context);
        this.currentUser = new CurrentUser(context);
        this.data = new Data(context);
        this.interaction = new Interaction(context);
        this.linkAccountUser = new LinkAccountUser(context);
        this.report = new Report(context);
        this.service = new Service(context);
        this.user = new User(context);
        this.webauthz = new Webauthz(context);
    }
}

export {
    Client,
    Account,
    Authn,
    CurrentUser,
    Data,
    Interaction,
    LinkAccountUser,
    Report,
    Service,
    User,
    Webauthz,
};
